.screen-container {
    padding-top: 10vh;
    align-items: center;
    scroll-behavior: auto;
}

.hidden {}

.show {}

.content-box {
    text-align: center;
    width: 100%;
    padding-top: 5vh;
    padding-bottom: 5vh;
}


.content-box-bg-dark {
    min-height: 100vh;
    padding: 2vh;
    background-color: #121212;
}

.content-box-bg-black {
    background-color: black;
    padding: 2vh;
}


.content-box-bg-gray {
    background-color: #1e1e1e;
    min-height: 100vh;
    padding: 2vh;

}

.background-gif-3 {
    background-image: linear-gradient(to top, rgba(245, 246, 252, 0.1), #1e1e1e), url('../../assets/home/background.gif');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh
}

.background-gif-1 {
    background-image: linear-gradient(to top, rgba(30, 30, 30, 0.3), black), linear-gradient(to bottom, rgba(18, 18, 18, 0.1), rgba(18, 18, 18, 0.5), #121212), url('../../assets/home/background3.gif');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh
}

.background-gif-2 {
    background-image: linear-gradient(to top, rgba(30, 30, 30, 0.1), #121212), linear-gradient(to bottom, rgba(18, 18, 18, 0.5), rgba(18, 18, 18, 0.5), #1e1e1e), url('../../assets/home/background2.gif');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh
}

.grid {
    display: grid;
    max-width: 95vw;
    grid-template-columns: 20vw 20vw 20vw 20vw;
    row-gap: 5vh;
    border-radius: 4px;
    grid-gap:150px;
    max-height: 65vh;
    padding:5vw;
    overflow-y: auto;
}

@media only screen and (max-width: 768px) {
    .grid {
        display: grid;
        max-width: 100vw;
        grid-template-columns: 80vw;
        row-gap: 5vh;
        border-radius: 4;
        max-height: 65vh;
        overflow-y: auto;
        justify-content: center;
    }
}

.frameworks-grid {
    display: grid;
    grid-template-columns: 10vw 10vw 10vw 10vw;
    grid-column-gap: 1vw;
    border-radius: 4px;
    row-gap: 5vh;
    overflow-y: auto;
}

@media only screen and (max-width: 768px) {
    .frameworks-grid {
        display: grid;
        grid-template-columns: 50vw;
        row-gap: 5vh;
        border-radius: 4;
        padding: 10;
        max-height: 65vh;
        overflow-y: auto;
    }
}

.unselectable {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.arrow-down {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 26px solid goldenrod;
}

.arrow-up {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 26px solid goldenrod;
}