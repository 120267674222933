.stat-block-grid {
    display: grid;
    max-width: 100vw;
    grid-template-columns: 35vw 35vw;
    grid-column-gap: 1vw;
    border-radius: 4px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding: 50px 10px 50px 10px;
    grid-template-rows: 65vh;
    border: 1px solid #ec4145;
}

.stat-block-image {
    border-radius: 4px;
    max-width: 100%;
    max-height: 50%;
    overflow-y: auto;
}

.character-text {
    max-width: 90%;
    max-height: 50%;
    overflow-y: auto;
    text-align: justify;
    padding: 10px;
}


.map-tag-text {
    font-size: 12px;
    position: absolute;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
}

.timeline-container {
    display: flex;
    flex-direction: row;
    gap: 5vw;
    width: 80%;
    justify-content: center;
    margin-top: 5vh;
    margin-left: 5vw;
    margin-right: 5vw;
}

.timeline-description-container {
    display: flex;
    flex-direction: column;
    gap:3vh;
    width: 40vw;
}

.timeline-divider{
    height: 85vh;
    width: 1px;
}

.map-description-container {
    position: absolute;
    top: 45%;
    left: 72%;
    width: 25%;
    max-height: 50%;
    padding: 1%;
    border-radius: 4px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap:3vh;
}

.map-enlarge-button {
    position: absolute;
    top: 0%;
    left: 2%;
    height:0;
    visibility: hidden;
}

@media only screen and (max-width: 768px) {
    .character-text {
        max-height: fit-content;
        overflow-y: auto;
        padding: 10px;
        max-width: 100%;
        text-align: justify;

    }

    .map-tag-text {
        font-size: 6px;
    }

    .timeline-container {
        display: flex;
        flex-direction: column;
        gap: 5vw;
        width: 80%;
        justify-content: center;
        margin-top: 5vh;
        margin-left: 5vw;
        margin-right: 5vw;
    }
    .timeline-divider{
        width: 85vw;
        height: 1px;
    }
    .timeline-description-container {
        display: flex;
        flex-direction: column;
        gap:3vh;
        width: 85vw;
    }

    .stat-block-grid {
        display: grid;
        width: 90vw;
        align-items: center;
        justify-content: center;
        grid-template-columns: 90vw;
        max-height: fit-content;
        border-radius: 4;
        overflow-y: auto;
        padding-top: 10px;
        grid-template-rows: auto;
        overflow-x: hidden;
    }

    .stat-block-image {
        border-radius: 4px;
        max-height: fit-content;
        max-width: 100%;
    }
    .map-description-container {
        position: absolute;
        max-height: 50%;
        padding: 1%;
        border-radius: 4px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap:3vh;
        padding: 10px;
    }
    .map-enlarge-button {
        position: absolute;
        top: 0%;
        left: 2%;
        visibility: visible;
    }
}